<template>
  <div class="testSetting-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="set-title">质检分数设置</div>
    <div>
      客服通过分数线
      <div class="common-btn-input-container">
        <el-input
          class="common-btn-input-input"
          type="number"
          placeholder="请输入质检分数"
          v-model="score"
        ></el-input>
        <div class="common-btn-input-btn" @click="setScore">确 认</div>
      </div>
      分
      <div class="setTip">
        通过分在1~100之间设置，若分数产生修改，不对历史数据产生作用
      </div>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { setScore, getScore } from "../../service/service.js";
import { mapState } from "vuex";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "质检设置", isLink: false },
      ],
      score: "", // 质检分数
    };
  },
  created() {
    this.getScore();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    async getScore() { // 获取质检分数
      let resData = (await getScore({companyId: this.comId})).data;
      this.score = resData;
    },
    async setScore() { // 设置质检分数
      let score = this.score;
      if (score) {
        await setScore({ score: this.score,companyId: this.comId });
        this.$message.success("设置成功");
      } else {
        this.$message.error("请输入质检通过分数");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.testSetting-container {
  text-align: left;
  .set-title {
    margin: 20px 0;
  }
  .setTip {
    display: inline-block;
    line-height: 32px;
    font-size: 14;
    color: #999;
    margin-left: 8px;
  }
}
</style>
